import api from './api';

class MariageDataService {
  getAll() {
    return api.get("/test/mariages/all");
  }

  get(id) {
    return api.get(`/test/mariages/${id}`);
  }

  create(data) {
    return api.post("/test/mariages", data);
  }

  update(id, data) {
    return api.put(`/test/mariage/add/${id}`, data);
  }

  delete(id) {
    return api.delete(`/test/mariages/${id}`);
  }

  deleteAll() {
    return api.delete(`/test/mariages`);
  }

  findByLieuDot(lieuDot) {
    return api.get(`/mariages?lieuDot=${lieuDot}`);
  }

  findByLieuCivil(lieuCivil) {
    return api.get(`/mariages?lieuCivil=${lieuCivil}`);
  }

  findByLieuReligieux(lieuReligieux) {
    return api.get(`/mariages?lieuReligieux=${lieuReligieux}`);
  }
}

export default new MariageDataService();