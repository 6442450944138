<template>
  <div class="list row">
    <div class="col-md-8">
      <div class="input-group mb-3">
        <input type="text" class="form-control" placeholder="Rechercher par libellé"
          v-model="libelle"/>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary" type="button"
            @click="searchLibelle"
          >
            Rechercher
          </button>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <h4>Liste Mariages</h4>
      <ul class="list-group">
        <li class="list-group-item"
          :class="{ active: index == currentIndex }"
          v-for="(mariage, index) in mariages"
          :key="index"
          @click="setActiveMariage(mariage, index)"
        >
          {{ mariage.libelle }}
        </li>
      </ul>

      <button class="m-3 btn btn-sm btn-danger" @click="removeAllMariages">
        Supprimer Tout
      </button>
    </div>
    <div class="col-md-6">
      <div v-if="currentMariage">
        <h4>Mariage</h4>
        <div>
          <label><strong>Libellé:</strong></label> {{ currentMariage.libelle }}
        </div>
        
        <div>
          <label><strong>Date dot:</strong></label> {{ currentMariage.dateDot }}
        </div>
        <div>
          <label><strong>Lieu dot:</strong></label> {{ currentMariage.lieuDot }}
        </div>
        <div>
          <label><strong>Statut dot:</strong></label> {{ currentMariage.estDotConfirme }}
        </div>

        <div>
          <label><strong>Date civil:</strong></label> {{ currentMariage.dateCivil }}
        </div>
        <div>
          <label><strong>Lieu civil:</strong></label> {{ currentMariage.lieuCivil }}
        </div>
        <div>
          <label><strong>Statut civil:</strong></label> {{ currentMariage.estCivilConfirme }}
        </div>

        <div>
          <label><strong>Date religieux:</strong></label> {{ currentMariage.dateReligieux }}
        </div>
        <div>
          <label><strong>Lieu religieux:</strong></label> {{ currentMariage.lieuReligieux }}
        </div>
        <div>
          <label><strong>Statut religieux:</strong></label> {{ currentMariage.estReligieuxConfirme }}
        </div>
        
        <div>
          <label><strong>Utilisateur:</strong></label> {{ currentMariage.user.username }}
        </div>

        <router-link :to="'/mariages/' + currentMariage.id" class="badge badge-warning">Modifier</router-link>
      </div>
      <div v-else>
        <br />
        <p>Veuillez cliquer sur un mariage SVP...</p>
      </div>
    </div>
  </div>
</template>

<script>
import MariageDataService from "../services/MariageDataService";

export default {
  name: "mariages-list",
  data() {
    return {
      mariages: [],
      currentMariage: null,
      currentIndex: -1,
      nom: ""
    };
  },
  methods: {
    retrieveMariages() {
      MariageDataService.getAll()
        .then(response => {
          this.mariages = response.data;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },

    refreshList() {
      this.retrieveMariages();
      this.currentMariage = null;
      this.currentIndex = -1;
    },

    setActiveMariage(mariage, index) {
      this.currentMariage = mariage;
      this.currentIndex = mariage ? index : -1;
    },

    removeAllMariages() {
      MariageDataService.deleteAll()
        .then(response => {
          console.log(response.data);
          this.refreshList();
        })
        .catch(e => {
          console.log(e);
        });
    },
    
    searchLibelle() {
      MariageDataService.findByLibelle(this.libelle)
        .then(response => {
          this.mariages = response.data;
          this.setActiveMariage(null);
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    }
  },
  mounted() {
    this.retrieveMariages();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>






libelle: String,
    dateDot: Date,
    lieuDot:  String,
    estDotConfirme: Boolean,
    dateCivil: Date,
    lieuCivil:  String,
    estCivilConfirme: Boolean,
    dateReligieux: Date,
    lieuReligieux: String,
    estReligieuxConfirme: Boolean,
    user: {
        type: mongoose.Schema.Types.ObjectId,
        ref: "User"
    }